import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { EShopIcon } from '../components/CustomIcons';
import AppTheme from '../shared-theme/AppTheme';
import Card from '../components/Card'
import SignInContainer from '../components/SignInContainer'
import { useLocation } from 'react-router-dom';

export default function ShopifyThankYou(props) {
    const location = useLocation();
    const [shop, setShop] = useState(null);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const shopUrl = searchParams.get('shop');
        setShop(shopUrl);
    }, [location]);

    console.log('shop', shop);

    const fireShopifyDisable = async () => {
      console.log('fired fireShopifyDisable')
      const url = `${process.env.REACT_APP_API_URL}/shopify/disable?shop=${shop}`;
      console.log('backend url: ',url)
      window.location.href = url;        
    };


    const handleDisableApp = () => {
        if (window.confirm('Are you sure you want to remove 8Shop from your Shopify store?')) {
            fireShopifyDisable();
        }
    }

    return (
      <AppTheme {...props}>
        <CssBaseline enableColorScheme />
        <SignInContainer direction="column" justifyContent="space-between">
          <Card variant="outlined">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <EShopIcon />
                <button onClick={handleDisableApp}>Disable App</button>
            </div>

            <Typography
            component="h1"
            variant="h1"
            sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
          >
            Thank You!
          </Typography>

            <Typography>
              Thank you for sharing your product details.
            </Typography>

            <Typography>
              Our service will be active within 24 hours.
            </Typography>

            <Typography>
              No further action needed from user.
            </Typography>
  
          </Card>
        </SignInContainer>
      </AppTheme>
    );
  }