import './styles/App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ShopifyLogin from './pages/ShopifyLogin';
import ShopifyThankYou from './pages/ShopifyThankYou';
import ShopifySad from './pages/ShopifySad';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<ShopifyLogin />} />
          <Route path="/shopify/thank-you" element={<ShopifyThankYou />} />
          <Route path="/shopify/sad" element={<ShopifySad />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;