import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ShopifyIcon, EShopIcon } from '../components/CustomIcons';
import AppTheme from '../shared-theme/AppTheme';
import {useState} from 'react';
import Card from '../components/Card'
import SignInContainer from '../components/SignInContainer'


export default function SignIn(props) {
  const [shopifyUrl, setShopifyUrl] = useState('');
//   const [isSignInLoading, setIsSignInLoading] = useState(false);

    const handleShopifyUrlChange = (e) => {
        setShopifyUrl(e.target.value);
    };

    const fireShopifyAuth = async () => {
        console.log('fired fireShopifyAuth')
        const url = `${process.env.REACT_APP_API_URL}/shopify/auth?shop=${shopifyUrl}`;
        console.log('backend url: ',url)
        // setIsSignInLoading(false);
        window.location.href = url;        
    };

    const handleShopifyLogin = async (e) => {
        e.preventDefault();
        if (shopifyUrl.trim() === '') {
            alert('Please enter a Shopify store URL');
            return;
        }
        // Basic URL structure validation
        const urlPattern = /^[a-zA-Z0-9][a-zA-Z0-9-]*\.myshopify\.com$/;
        if (!urlPattern.test(shopifyUrl.trim())) {
            alert('Please enter a valid Shopify store URL (e.g. mystore.myshopify.com)');
            return;
        }
        // setIsSignInLoading(true);
        await fireShopifyAuth();
    };
    return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <SignInContainer direction="column" justifyContent="space-between">
        <Card variant="outlined">
          <EShopIcon />
          <Typography
            component="h1"
            variant="h1"
            sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
          >
            Sign In
          </Typography>
          <Box
            component="form"
            // onSubmit={handleSubmit}
            noValidate
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: 2,
            }}
          >
            <FormControl>
              <FormLabel htmlFor="email">Enter your Shopify store URL below</FormLabel>
              <TextField
                type="text"
                placeholder="e.g. mystore.myshopify.com"
                autoFocus
                fullWidth
                value={shopifyUrl}
                onChange={handleShopifyUrlChange}
              />
            </FormControl>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={handleShopifyLogin}
              startIcon={<ShopifyIcon />}
            >
              Sign in with Shopify
            </Button>

            <Typography>
              Share your products from Shopify store with 8Shop
            </Typography>

          </Box>

        </Card>
      </SignInContainer>
    </AppTheme>
  );
}