import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { EShopIcon } from '../components/CustomIcons';
import AppTheme from '../shared-theme/AppTheme';
import Card from '../components/Card'
import SignInContainer from '../components/SignInContainer'

export default function ShopifySad(props) {
    return (
      <AppTheme {...props}>
        <CssBaseline enableColorScheme />
        <SignInContainer direction="column" justifyContent="space-between">
          <Card variant="outlined">
            <EShopIcon />

            <Typography
            component="h1"
            variant="h1"
              sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
            >
              App Uninstalled
            </Typography>

            <Typography>
              Thank you for using 8Shop.
            </Typography>

          </Card>
        </SignInContainer>
      </AppTheme>
    );
  }