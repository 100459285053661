import * as React from 'react';
import logo from '../assets/images/8ShopLogo.png';
import shopifyLogo from '../assets/images/ShopifyLogo.png';


export function EShopIcon() {
    const logoWidth = 617;
    const logoHeight = 196;
    const height = 25;
  return (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
        <div className="logo">
                <img src={logo} alt="8Shop Logo" style={{width: logoWidth/logoHeight*height, height: height}} />
        </div>
    </div>
  );
}

export function ShopifyIcon() {
  return (
    <img src={shopifyLogo} alt="Facebook Logo" style={{width: 20, height: 20}} />

  );
}